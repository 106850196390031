import "aos/dist/aos.css";
import React, { useEffect, useState, useCallback } from "react";

import PropagateLoader from "react-spinners/PropagateLoader";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useSelector } from "react-redux";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import PlanDisplay from "../components/PlanDetail/PlanDisplay.js";
import ShareLink from "../components/ShareLink.js";
import TreeDisplay from "../components/TreeView/TreeDisplay.js";
import DashBarDetail from "../components/dashBar/DashBarDetail.js";
import PackageHistory from "../components/dataTables/PackageHistory.js";
import WithdrawHistory from "../components/dataTables/WithdrawHistory.js";
import RoyaltyHistory from "../components/dataTables/RoyaltyHistory.js";
import LevelwiseHistory from "../components/dataTables/LevelwiseHistory.js";
import CheckingTable from "../components/dataTables/CheckingTable.js";
import DirectReferral from "../components/dataTables/DirectReferral.js";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { getUserDetails } from "../Action/api.Action";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

export default function DashBoard() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userData, setUserData] = useState({});
  const [royaltyList, setRoyaltyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [settings, setsettings] = useState([]);
  const [matchBonus, setmatchBonus] = useState([]);

  console.log(walletConnection, "walletConnection");
  const getUserData = useCallback(async () => {
    try {

      const data = await getUserDetails({ userID: walletConnection?.userdetails?.id });
      console.log(data, "datadatadatadata");
      setmatchBonus(data?.data?.matchRef[0] || {})
      setUserData(data?.data?.data[0] || {});
      setRoyaltyList(data?.data?.royaltyData || []);
      setsettings(data?.data?.settings || []);

    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [walletConnection?.userdetails?.id]);

  useEffect(() => {
    if (walletConnection.address ) {
      getUserData();
    }
  }, [walletConnection.address, getUserData]);

  console.log(matchBonus, "settings");

  return (
    <>
      <Navbar />
      <section className="dashboard_section">
        <Container>
          <div className="dashboard_head mt-3">
            <h3>DashBoard</h3>
            <div className="register_time">
              <img
                src={require("../assets/images/dashboard/clock.png")}
                className="img-fluid"
                alt="clock"
              />
              <p>
                Time of Registration :{" "}
                <span>
                  {moment(userData?.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                </span>
              </p>
            </div>
          </div>
          <section className="bg-sec">
            <DashBarDetail UserData={userData} settings={settings} type="orignal" />
          </section>
          <section className="bg-sec1 mb-5">
            <Row>
              <Col lg={3} md={12}>
                <div className="dashborad_rank_sec">
                  <div className="rank_board mt-5">
                    <h6>Rank</h6>
                    <img
                      src={require(`../assets/images/dashboard/star${(userData && userData?.currentRank) || 0
                        }.png`)}
                      className="img-fluid"
                      alt="star"
                    />
                  </div>
                  <div className="income_info missed_income withdraw_info mt-4">
                    <h3>Missed Earnings</h3>

                    <div class="dashboard_info">
                      <img
                        src={require("../assets/images/dashboard/di4.png")}
                        class="img-fluid"
                        alt=""
                      />
                      <div class="dashboard_text">
                        <h3>
                          {" "}
                          <span>($)</span>{" "}
                          {(matchBonus?.totalAmount || 0).toFixed(4)}{" "}
                        </h3>
                        <div className="warning-flex">
                          <p>Matching Bonus Eligible</p>
                          <a
                            href="#"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={"Matching Bonus Eligible"}
                            data-tooltip-place="top"
                          >
                            <i class="fas fa-info-circle"></i>
                          </a>
                          <Tooltip id="my-tooltip" />
                        </div>
                      </div>
                    </div>
                    <div class="dashboard_info">
                      <img
                        src={require("../assets/images/dashboard/di4.png")}
                        class="img-fluid"
                        alt=""
                      />
                      <div class="dashboard_text">
                        {/* {userData?.referrals?.length > 2 ? */}
                        <h3>
                          {" "}
                          <span>($)</span>{" "}
                          {(userData?.refMissEarn || 0).toFixed(4)}{" "}
                        </h3>
                        {/* :
                          <h3 > <span>($)</span> {(0).toFixed(4)} </h3>
                        } */}
                        <div className="warning-flex">
                          <p>Matching Bonus Missed Earnings</p>
                          {/* Matching Bonus eligible ${matchBonus} */}

                          {/* <p></p> */}
                          <a href="#"
                            data-tooltip-id="my-tooltip2"
                            data-tooltip-content={"Need to refer 2 members"}
                            data-tooltip-place="top"
                          >
                            <i class="fas fa-info-circle"></i>
                          </a>
                          <Tooltip id="my-tooltip2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="income_info withdraw_info mt-4">
                    <h3>Income</h3>
                    {[
                      {
                        label: "Total Earned Incentive",
                        value: userData?.totalIncome,
                        key: "totalIncome",
                      },
                      {
                        label: "Matching Incentive Achieved",
                        value: userData?.MatchinRefInce,
                        key: "MatchinRefInce",
                      },
                      {
                        label: "Direct Referral Incentive",
                        value: userData?.directRefInce,
                        key: "directRefInce",
                      },
                      {
                        label: "Daily Trading Incentive",
                        value: userData?.dailyTradIncen,
                        key: "dailyTradIncen",
                      },
                      {
                        label: "Trading Passive Incentive",
                        value: userData?.PassiveIncen,
                        key: "PassiveIncen",
                      },
                      // {
                      //   label: "Matching Bonus Eligible",
                      //   value: matchBonus?.totalAmount || 0,
                      //   key: "BonusEligible",
                      // },
                    ].map((item, index) => (

                      <div className="dashboard_info" key={index}>
                        {console.log(item, "itemitem")}
                        <img
                          src={require(`../assets/images/dashboard/di${index + 1
                            }.png`)}
                          className="img-fluid"
                          alt={item.label}
                        />
                        <div className="dashboard_text">
                          <h3>
                            <span>($)</span> {(item?.value || 0).toFixed(4)}
                          </h3>
                          <p>{item.label}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="income_info withdraw_info withdrow_bg mt-5">
                    {[
                      {
                        label: "Direct Team Count",
                        value: userData?.directReferalUsers,
                      },
                      {
                        label: "Total Team Count",
                        value:
                          (userData?.overAllLeftUser || 0) +
                          (userData?.overAllRightUser || 0),
                      },
                      { label: "Left Group", value: userData?.overAllLeftUser },
                      {
                        label: "Right Group",
                        value: userData?.overAllRightUser,
                      },
                    ].map((item, index) => (
                      <div className="dashboard_info" key={index}>
                        <img
                          src={require(`../assets/images/dashboard/di${index + 6
                            }.png`)}
                          className="img-fluid"
                          alt={item.label}
                        />
                        <div className="dashboard_text">
                          <h3>{item.value || 0}</h3>
                          <p>{item.label}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="rank_board referal_link mt-5">
                    <ShareLink />
                  </div>
                </div>
              </Col>
              <Col lg={9}>
                <div className="income_info mt-5">
                  <h3>Total Earnings</h3>
                  <div class="progress mt-4">
                    <div
                      class="progress-bar progress-bar-striped"
                      role="progressbar"
                      style={{
                        width: `${Math.floor(
                          (userData?.totalIncome /
                            (userData?.investAmount *
                              settings?.cappingIncentive)) *
                          100
                        )}%`,
                      }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="progress-content">
                    <p>
                      Earned {(userData?.totalIncome || 0).toFixed(4)} $ /{" "}
                      {userData?.investAmount * settings?.cappingIncentive|| 0} $
                    </p>
                    <p>
                      {Math.floor(
                        (userData?.totalIncome /
                          (userData?.investAmount *
                            settings?.cappingIncentive)) *
                        100
                      )|| 0}
                      % Earnings Completed
                    </p>
                  </div>
                </div>
                <div className="income_info mt-5">
                  <PlanDisplay UserData={userData} type="orignal" />
                </div>
                <div className="income_info mt-5">
                  <TreeDisplay settings={settings} />
                </div>
                <div className="income_info table_content mt-5">
                  <h3>Trading Passive Incentive</h3>
                  <LevelwiseHistory
                    tradPassInceHistory={userData?.tradPassInceHistory}
                  />
                </div>
                <div className="income_info table_content mt-3">
                  <h3>Royalty Rewards </h3>
                  <RoyaltyHistory
                    Royaltylist={royaltyList}
                    User={userData}
                  />
                </div>
                <div className="income_info table_content mt-3">
                  <h3>Package History</h3>
                  <PackageHistory
                    userPlanHist={userData?.userPlanHist}
                    userTradInceHistory={userData?.userTradInceHistory}
                  />
                </div>
                <div className="income_info table_content mt-3">
                  <h3>Withdraw History</h3>
                  <WithdrawHistory
                    withdrawHistory={userData?.withdrawHistory}
                    type="orignal"
                  />
                </div>
                {/* <div className="income_info table_content mt-3">
                  <h3>Direct Referral Incentive History</h3>
                  <DirectReferral History={userData}  />
                </div>
                

                <CheckingTable History={userData} /> */}
              </Col>
            </Row>
          </section>
        </Container>
      </section>
      <Footer />
      {loading && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#eba6fb"}
            loading={loading}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
