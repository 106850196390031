import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Web3 from "web3";
import config from "../../config/index";
import PropagateLoader from "react-spinners/PropagateLoader";
import TokenABI from "../../ABI/TokenContractAbi.json";
import ContractAbi from "../../ABI/MLMContractAbi.json";
import { toastAlert } from "../../helper/toastAlert";
import { getTransactionInfo } from "../../helper/checkhash";
import { convert } from '../../helper/convert';

const loaderStyle = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const PlanDisplay = ({ UserData, type }) => {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loader, setLoader] = useState(false);
  const [poolInfo, setPoolInfo] = useState([]);
  const [SelectedPlan, setSelectedPlan] = useState();

  useEffect(() => {
    if (walletConnection.address && UserData) {
      getPoolDetails();
    }
  }, [walletConnection?.address, UserData]);

  const getPoolDetails = async () => {
    if (!walletConnection?.userdetails?.isExist) {
      window.location.href = "/";
      return;
    }

    try {
      setLoader(true);

      const web3 = new Web3(walletConnection?.web3);
      const contract = new web3.eth.Contract(ContractAbi, config.Contract);
      const poolLength = await contract.methods.getPoolLenght().call();
      let getTokenprice = await contract.methods.tokenPrice().call();

      const pools = await Promise.all(
        Array.from({ length: poolLength }, (_, i) => i).map(async (i) => {
          const poolData = await contract.methods.poolInfo(i).call();
          const poolInfoData = await contract.methods.userPoolInfo(i, walletConnection?.address).call();

          return {
            poolNo: i,
            poolAmount: Number(poolData.poolAmount) / 1e18,
            isExits: poolInfoData.isExist,
            currentPlan: UserData?.currentPlan,
            nowPlan: UserData?.currentPlan === i,
            nextPlan: UserData?.currentPlan + 1 === i,
            tokenPrice: (getTokenprice / 1e18)
          };
        })
      );
      // console.log(pools, "pools");
      setPoolInfo(pools);
    } catch (error) {
      console.error(error);
      toastAlert("error", "Failed to fetch pool details");
    } finally {
      setLoader(false);
    }
  };





  const upgradePlan = async (poolId) => {
    console.log(poolId, "poolId");
    if (type == "check") {
      toastAlert("warning", "You can't do Anthing Here :)");
      return false;
    }
    if (!walletConnection.address) return;

    try {
      setLoader(true);
      const web3 = new Web3(walletConnection?.web3);
      const contractToken = new web3.eth.Contract(TokenABI, config.TokenContract);
      const contract = new web3.eth.Contract(ContractAbi, config.Contract);

      const { poolAmount } = await contract.methods.poolInfo(poolId).call();
      let getTokenprice = await contract.methods.tokenPrice().call();

      let tokenprice = getTokenprice / 1e18

      const planAmount = Number(poolAmount * tokenprice) / config.decimalVal;

      const tokenBalance = await contractToken.methods.balanceOf(walletConnection.address).call();

      if (Number(planAmount) > Number(tokenBalance / 1e18)) {
        toastAlert("error", "Your YEZB balance is low");
        setLoader(false);
        return;
      }
      console.log(planAmount, "amtamt1");

      var amt = (planAmount * 1e18).toString()
      amt = await convert(amt);

      console.log(amt, "amtamt");

      const gasPrice = await web3.eth.getGasPrice();
      const estimateGas = await contractToken.methods
        .approve(config.Contract, amt)
        .estimateGas({ from: walletConnection.address });

      const gasFee = (estimateGas * gasPrice) / 1e18;
      const balance = await web3.eth.getBalance(walletConnection.address);

      if (gasFee > balance / 1e18) {
        toastAlert("error", "Your BNB balance is low");
        setLoader(false);
        return;
      }

      await contractToken.methods.approve(config.Contract, amt).send({ from: walletConnection.address });

      await contract.methods.rejoin(poolId).send({ from: walletConnection?.address }).then(async (res) => {
        const result = await getTransactionInfo(res.transactionHash);
        if (result) {
          toastAlert("success", "Upgrade Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      });
    } catch (error) {
      console.error(error);
      toastAlert("error", "Transaction Rejected");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <h3>Packages</h3>
      <div className="package_info mt-4">
      {poolInfo.map((item, index) => {
        const isRejoin = item.isExits && item.currentPlan === item.poolNo;
        const isJoined = item.isExits && item.currentPlan !== item.poolNo;
        const isDisabled = !item.isExits && item.currentPlan > item.poolNo;
        const isReJoinable = !item.isExits && item.currentPlan <= item.poolNo && !walletConnection?.userdetails?.isBlocked;

        return (
          <div
            key={index}
            className={`dashboard_info ${
              isRejoin ? 're_join' : isJoined ? 'joined' : isDisabled ? 'disabled' : 're_join'
            }`}
          >
            <div className="dashboard_text">
              <h3>${item.poolAmount}</h3>
              <p>{((item.poolAmount || 0) * (item.tokenPrice || 0)).toFixed(4)} YEZB Token</p>

              {isReJoinable && (
                <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#Rejoin_popUp"
                  onClick={() => setSelectedPlan(item)}
                >
                  Join
                </button>
              )}

              {isRejoin && (
                <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#Rejoin_popUp"
                  onClick={() => setSelectedPlan(item)}
                >
                  Rejoin
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  

      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#eba6fb"}
            loading={loader}
            cssOverride={loaderStyle}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <div
        className="modal fade primary_modal"
        id="Rejoin_popUp"
        tabIndex={-1}
        aria-labelledby="Rejoin_popUp"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="Rejoin_popUp">
                Info
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">

              <table className='table table-bordered text-center'>
                <thead>
                  <tr>
                    <th>Plan Price($)</th>
                    <th>Token Price(YEZB)</th>
                    <th>Total price(YEZB)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{SelectedPlan?.poolAmount}</td>
                    <td>{(SelectedPlan?.tokenPrice || 0).toFixed(2)}</td>
                    <td>{(SelectedPlan?.poolAmount * SelectedPlan?.tokenPrice).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>


              <div className='text-center popupPara'> Note* : Need {(SelectedPlan?.poolAmount * SelectedPlan?.tokenPrice).toFixed(4)} YEZB Token To purchase this plan</div>
            </div>

            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <button onClick={() => upgradePlan(SelectedPlan.poolNo)} className="primary_btn mt-0 mx-auto d-block">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};



export default PlanDisplay;
