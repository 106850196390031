import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { useDispatch, useSelector } from "react-redux";
import { seaarchUserTree } from "../../Action/api.Action";
import { validPositive } from "../../helper/formatAddr";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function TreeDisplay(props) {
  const { settings } = props;
  const walletConnection = useSelector((state) => state.walletConnection);
  const [binaryTree, setbinaryTree] = useState([]);
  const [srchLR, setSrchLR] = useState(false);
  const [searchId, setSearchId] = useState(walletConnection?.userdetails?.id);
  const [searchload, setsearchload] = useState(false);
  const [isvalid, setisvalid] = useState(false);

  useEffect(() => {
    searchClick();
  }, [walletConnection.address, searchId]);

  const searchClick = async () => {
    setsearchload(true);
    let payload = {
      userId: searchId ? searchId : walletConnection?.userdetails?.id,
    };
    const data = await seaarchUserTree(payload);
    if (data?.user?.data[0]?.item["key"] == 0) {
      setisvalid(true);
    } else {
      console.log(data,"datadata");
      setbinaryTree(data);
      setisvalid(false);
    }
    setsearchload(false);
  };

  var userLevleft;
  var userLevright;

  var userLev1left = "Not Found";
  var userLev1right = "Not Found";

  var userLev2left = "Not Found";
  var userLev2right = "Not Found";

  const Funn = () => {
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.user == searchId) {
        setSrchLR(true);
        break;
      }
    }
  };
  useEffect(() => {
    Funn();
  }, [searchId]);

  const searchchange = (e) => {
    var ValNum = parseInt(e.target.value);
    if (ValNum == null) {
      setSearchId(walletConnection?.userdetails?.id);
    } else {
      setSearchId(ValNum);
    }

    // alert(VaNum);
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.key == ValNum) {
        setSrchLR(true);
        break;
      } else {
        setSrchLR(false);
      }
    }
  };

  return (
    <>
      <div className="search_bar mb-3">
        <h3>My Tree</h3>
        <Form.Group>
          <InputGroup className="text_box_1 ">
            <Form.Control
              placeholder=""
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className="custom_placeholder"
              onChange={searchchange}
              onInput={validPositive}
            />

            <InputGroup.Text id="basic-addon2" className="deposit_box-1">
              {searchload && (
                <i
                  class="fa fa-spinner fa-spin checkspin"
                  style={{ fontSize: "22px" }}
                ></i>
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>

      <Row>
        <Col lg={6}>
          <div class="tree">
            {!searchId ? (
              <ul>
                {binaryTree &&
                  binaryTree?.user &&
                  binaryTree?.user?.data &&
                  binaryTree?.user.data.map((item, index) => {
                    if (item.item.key == walletConnection?.userdetails?.id)
                      return (
                        <li>
                          <span style={{ display: "none" }}>
                            {
                              (userLevleft =
                                item.item.left == 0
                                  ? "Not Found"
                                  : item.item.left)
                            }{" "}
                            {
                              (userLevright =
                                item.item.right == 0
                                  ? "Not Found"
                                  : item.item.right)
                            }
                          </span>
                          <a
                            href="#"
                            className="root_img"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID ${
                              item.item.key || "Not Found"
                            }`}
                            data-tooltip-place="top"
                          >
                            <img
                              src={require("../../assets/images/dashboard/men_2.png")}
                            />
                          </a>
                          <Tooltip id="my-tooltip" />
                          <ul className="second_chat">
                            <li>
                              <a
                                href="#"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`ID ${userLevleft}`}
                                data-tooltip-place="top"
                              >
                                {" "}
                                <img
                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                />
                              </a>

                              <Tooltip id="my-tooltip" />

                              <>
                                {binaryTree &&
                                  binaryTree?.user &&
                                  binaryTree?.user?.data &&
                                  binaryTree?.user.data.map((item, index) => {
                                    if (
                                      userLevleft != 0 &&
                                      item.item.key != 0 &&
                                      item.item.key === userLevleft
                                    )
                                      return (
                                        <span style={{ display: "none" }}>
                                          {(userLev1left = item.item.left)}{" "}
                                          {(userLev1right = item.item.right)}
                                        </span>
                                      );
                                  })}
                                <ul className="third_chat">
                                  {/* <> */}
                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev1left == 0
                                          ? "Not Found"
                                          : userLev1left
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>

                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev1right == 0
                                          ? "Not Found"
                                          : userLev1right
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>
                                  {/* </> */}
                                </ul>
                              </>
                            </li>

                            <li>
                              <a
                                href="#"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`ID ${userLevright}`}
                                data-tooltip-place="top"
                              >
                                {" "}
                                <img
                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                />
                              </a>{" "}
                              <Tooltip id="my-tooltip" />
                              <>
                                {binaryTree &&
                                  binaryTree?.user &&
                                  binaryTree?.user?.data &&
                                  binaryTree?.user.data.map((item, index) => {
                                    if (
                                      userLevright != 0 &&
                                      item.item.key != 0 &&
                                      item.item.key === userLevright
                                    )
                                      return (
                                        <span style={{ display: "none" }}>
                                          {(userLev2left = item.item.left)}{" "}
                                          {(userLev2right = item.item.right)}
                                        </span>
                                      );
                                  })}

                                <ul className="third_chat">
                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev2left == 0
                                          ? "Not Found"
                                          : userLev2left
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>

                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev2right == 0
                                          ? "Not Found"
                                          : userLev2right
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>
                                </ul>
                              </>
                            </li>
                          </ul>
                        </li>
                      );
                  })}
              </ul>
            ) : (
              <ul>
                {isvalid ? (
                  <h3>UserID Not Found</h3>
                ) : (
                  binaryTree &&
                  binaryTree?.user &&
                  binaryTree?.user?.data &&
                  binaryTree?.user.data.map((item, index) => {
                    if (item.item.key == searchId)
                      return (
                        <li>
                          <span style={{ display: "none" }}>
                            {
                              (userLevleft =
                                item.item.left == 0
                                  ? "Not Found"
                                  : item.item.left)
                            }{" "}
                            {
                              (userLevright =
                                item.item.right == 0
                                  ? "Not Found"
                                  : item.item.right)
                            }
                          </span>
                          <a
                            href="#"
                            className="root_img"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={`ID ${
                              item.item.key || "Not Found"
                            }`}
                            data-tooltip-place="top"
                          >
                            <img
                              src={require("../../assets/images/dashboard/men_2.png")}
                            />
                          </a>
                          <Tooltip id="my-tooltip" />
                          <ul className="second_chat">
                            <li>
                              <a
                                href="#"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`ID ${userLevleft}`}
                                data-tooltip-place="top"
                              >
                                {" "}
                                <img
                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                />
                              </a>

                              <Tooltip id="my-tooltip" />

                              <>
                                {binaryTree &&
                                  binaryTree?.user &&
                                  binaryTree?.user?.data &&
                                  binaryTree?.user.data.map((item, index) => {
                                    if (
                                      userLevleft != 0 &&
                                      item.item.key != 0 &&
                                      item.item.key === userLevleft
                                    )
                                      return (
                                        <span style={{ display: "none" }}>
                                          {(userLev1left = item.item.left)}{" "}
                                          {(userLev1right = item.item.right)}
                                        </span>
                                      );
                                  })}
                                <ul className="third_chat">
                                  {/* <> */}
                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev1left == 0
                                          ? "Not Found"
                                          : userLev1left
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>

                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev1right == 0
                                          ? "Not Found"
                                          : userLev1right
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>
                                  {/* </> */}
                                </ul>
                              </>
                            </li>

                            <li>
                              <a
                                href="#"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`ID ${userLevright}`}
                                data-tooltip-place="top"
                              >
                                {" "}
                                <img
                                  src={require("../../assets/images/dashboard/chat_men.png")}
                                />
                              </a>{" "}
                              <Tooltip id="my-tooltip" />
                              <>
                                {binaryTree &&
                                  binaryTree?.user &&
                                  binaryTree?.user?.data &&
                                  binaryTree?.user.data.map((item, index) => {
                                    if (
                                      userLevright != 0 &&
                                      item.item.key != 0 &&
                                      item.item.key === userLevright
                                    )
                                      return (
                                        <span style={{ display: "none" }}>
                                          {(userLev2left = item.item.left)}{" "}
                                          {(userLev2right = item.item.right)}
                                        </span>
                                      );
                                  })}

                                <ul className="third_chat">
                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev2left == 0
                                          ? "Not Found"
                                          : userLev2left
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>

                                  <li>
                                    <a
                                      href="#"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={`ID ${
                                        userLev2right == 0
                                          ? "Not Found"
                                          : userLev2right
                                      }`}
                                      data-tooltip-place="top"
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/dashboard/chat_men.png")}
                                      />
                                    </a>{" "}
                                    <Tooltip id="my-tooltip" />
                                  </li>
                                </ul>
                              </>
                            </li>
                          </ul>
                        </li>
                      );
                  })
                )}
              </ul>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div class="black-box mt-4">
            <div class="black-box1">
              <p>
                Direct <br></br> Referral
              </p>
              <h2>{settings?.directRefPer} %</h2>
            </div>
            <hr></hr>
            <div class="black-box1">
              <p>
                Matching Referral <br></br>Incentives
              </p>
              <h2>{settings?.MatchRefPer} %</h2>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default TreeDisplay;
