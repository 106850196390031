let key = {};

let Envname = "demo";

if (Envname === "prodction") {

    key = {
        frontend: "https://vforrce.com",
        NetworkId: 56,
        frontUrl: "https://vforrce.com/",
        Server_URL: "https://prodapi.vforrce.com/",

        Contract: "0xa27b7552C21B2E9fF933ac3670C0d78201908B9B",
        TokenContract: "0x9d22f85528f214e52accdbb36049fdf929feb600",
        defalutAddr: "0xb071fC2618f5fFDBa5527D6d72628105E1288E9E",

        rpcurl: "https://bsc-rpc.publicnode.com",

        bscscan: "https://bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a"

    };


} else if (Envname === "demo") {

    key = {
        frontend: "https://yezbitcoin-staking-vforce-demo.pages.dev",
        NetworkId: 97,
        frontUrl: "https://yezbitcoin-staking-vforce-demo.pages.dev/",

        Server_URL: "https://yezbitcoinstakingapi.wearedev.team/",

        Contract: "0x4375e725380239f060123Ed371c5B51C8790Bd6F",
        TokenContract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",

        rpcurl: "https://bsc-testnet-rpc.publicnode.com",

        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://testnet.bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a"

    };

} else {
    key = {
        frontend: "http://localhost:3000",
        NetworkId: 97,
        frontUrl: "http://localhost:3000/",

        Server_URL: "http://localhost:3040/",

        Contract: "0x4375e725380239f060123Ed371c5B51C8790Bd6F",
        TokenContract: "0x9F443A4349fb238A3E3381361fF3AEFcB5C82137",
        defalutAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",

        rpcurl: "https://bsc-testnet-rpc.publicnode.com",

        bscscan: "https://testnet.bscscan.com/",
        decimalVal: 1e18,
        txURl: "https://testnet.bscscan.com/tx/",
        projectId: "cb89ebb21cdccb2e1b591e189e27706a"

    };
}

export default key;
