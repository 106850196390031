import React, { useState,useEffect } from 'react'

function Mode() {


    const [darkMode, setDarkMode] = useState(localStorage.getItem('mode') === 'dark');
    const [lightMode, setLightMode] = useState(localStorage.getItem('mode') !== 'dark');

    useEffect(() => {
        const body = document.body;
        if (darkMode) {
            body.classList.add('dark');
            body.classList.remove('light');
            localStorage.setItem('mode', 'dark');
        } else if (lightMode) {
            body.classList.add('light');
            body.classList.remove('dark');
            localStorage.setItem('mode', 'light');
        }
    }, [darkMode, lightMode]);

    const toggleDarkMode = () => {
        setDarkMode(true);
        setLightMode(false);
    };

    const toggleLightMode = () => {
        setLightMode(true);
        setDarkMode(false);
    };

    return (
       <>
            <img
                src={require("../assets/images/light.png")}
                width={15}
                height={17}
                className="img-fluid light-img"
                onClick={toggleDarkMode}
            />{" "}
            <img
                src={require("../assets/images/light1.png")}
                width={15}
                height={17}
                className="img-fluid light1-img"
                onClick={toggleDarkMode}
            />{" "}
            <img
                src={require("../assets/images/dark.png")}
                width={22}
                height={23}
                className="img-fluid dark-img"
                onClick={toggleLightMode}
            />{" "}
            <img
                src={require("../assets/images/dark1.png")}
                width={22}
                height={23}
                className="img-fluid dark1-img"
                onClick={toggleLightMode}
            />{" "}
        </>)
}

export default Mode