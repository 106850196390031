import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import ConnectWallet from "../components/ConnectWallet.js";
import { useDispatch, useSelector } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const walletConnection = useSelector((state) => state.walletConnection);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      {/* <ScrollToTopOnMount /> */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="head1">
                <h1>
                  Embrace the <span>future and embark</span> on a path to a
                  better life.
                </h1>
                <p className="para">
                  Join now and start your journey to financial independence with
                  our Matrix referral platform. Let's thrive together!
                </p>

                {/* <button
                  className="primary_btn" 
                > 
                <span class="icon">
                    <img
                      src={require("../assets/images/wallet.png")}
                      width={20}
                      height={20}
                      className="img-fluid"
                    />{" "}
                  </span>
                  <span class="text2">Connect Wallet </span> */}

                {/* </button> */}
                {!walletConnection?.address ? <ConnectWallet /> :
                  walletConnection?.userdetails?.isExist ?
                    <button
                      className="primary_btn"
                    >
                      <a className="dropdown-item" href="/dashboard">
                        Dashboard
                      </a>

                    </button> : <button
                      className="primary_btn"
                    >    <a className="dropdown-item" href="/join-now">
                        Join now
                      </a>

                    </button>


                }
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="img_box">
                <div class="side">
                  <img
                    src={require("../assets/images/banner1.png")}
                    width={880}
                    height={881}
                    className="img-fluid "
                  />{" "}
                </div>
              </div>
              {/* <div id="main">
              <div class="coin">
                <div class="tails"></div>
                <div class="heads"></div>
              </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="ban2-sec">
          <Container>
            <div>
              <Row>
                <Col
                  lg="6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div className="sec2-right">
                    <h6>YOUR FUTURES ARE MADE WITH</h6>
                    <h2>FINANCIAL FREEDOM</h2>
                    <p>
                    Associating with us gives you the opportunity to impact
global financial markets every day – whether you work
on
our
industry-leading
technology
and
risk
management services, our benchmark products or in a
day to day life services area that helps all our
associates in a win win situation.{" "}
                    </p>
                  </div>
                  <div
                    className="sec2-card mb-5"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <div className="sec2-card-content">
                      {" "}
                      <img
                        src={require("../assets/images/icon1.png")}
                        width={19}
                        height={25}
                        className="img-fluid "
                      />{" "}
                      <p>Risk-Free Invesment</p>
                    </div>
                    <p>
                      Phasellus sagittis gravida lacus. Curabitur nec quam
                      tellus. Integer eget laoreet lacus, vel mattis tellus.
                      Nunc lacinia dictum posuere. Mauris in dui nibh.{" "}
                    </p>
                  </div>
                  <div
                    className="sec2-card "
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <div className="sec2-card-content">
                      {" "}
                      <img
                        src={require("../assets/images/icon2.png")}
                        width={19}
                        height={25}
                        className="img-fluid "
                      />{" "}
                      <p>Risk-Free Invesment</p>
                    </div>
                    <p>
                      Phasellus sagittis gravida lacus. Curabitur nec quam
                      tellus. Integer eget laoreet lacus, vel mattis tellus.
                      Nunc lacinia dictum posuere. Mauris in dui nibh.{" "}
                    </p>
                  </div>
                </Col>
                <Col
                  lg="6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div className="sec2-left">
                    <img
                      src={require("../assets/images/ban2-side.png")}
                      width={456}
                      height={453}
                      className="img-fluid mx-auto d-block ban2img"
                    />{" "}
                    <div
                      className="sec2-card mt-3 mb-5"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <div className="sec2-card-content">
                        {" "}
                        <img
                          src={require("../assets/images/icon3.png")}
                          width={19}
                          height={25}
                          className="img-fluid "
                        />{" "}
                        <p>Risk-Free Invesment</p>
                      </div>
                      <p>
                        Phasellus sagittis gravida lacus. Curabitur nec quam
                        tellus. Integer eget laoreet lacus, vel mattis tellus.
                        Nunc lacinia dictum posuere. Mauris in dui nibh.{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section
          className="ban3-sec"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <Container>
            <div className="sec3-bg">
              <p>Plan Explained</p>
              <h2>How It Works?</h2>
              <div>
                <Row
                  className="box-items"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <Col lg="6">
                    <img
                      src={require("../assets/images/tree.png")}
                      width={651}
                      height={438}
                      className="img-fluid "
                    />
                  </Col>
                  <Col lg="6">
                    <div className="black-box">
                      <div className="black-box1">
                        <p>
                          Direct<br></br> Referral
                        </p>
                        <h2>10%</h2>
                      </div>
                      <hr></hr>
                      <div className="black-box1">
                        <p>
                          Matching Referral<br></br> Incentives
                        </p>
                        <h2>20%</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <div className="sec3-content">
                  <img
                    src={require("../assets/images/tree.png")}
                    width={651}
                    height={438}
                    className="img-fluid "
                  />{" "}
                  <div className="black-box">
                    <div className="black-box1">
                      <p>Direct Referral</p>
                      <h2>10%</h2>
                    </div>
                    <div className="black-box1">
                      <p>Matching Referral Incentives</p>
                      <h2>20%</h2>
                    </div>
                  </div>
                </div> */}
            </div>
          </Container>
        </section>
        <section class="faq">
          <div class="container">
            <div
              class="head aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 class=" text-center mx-auto mb-3">TERMS & CONDITIONS</h2>
              <p class=" text-center mx-auto mb-5">
                You can send email with your questions and we'll give your
                answer
              </p>
            </div>
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                type="button"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                   DAILY TRAING INCENTIVE
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    0.4 % Daily Up To 500 Days{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item aos-init aos-animate "
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                   REFERRAL INCENTIVE
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    10% Instant For Withdrawal{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    MATCHING INCENTIVE
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    20% Will Be Credited 2% X 50 Days{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    CAPPING INCENTIVE
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    Inclusive Of All Rewards Up To 300%{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1200"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    MATCHING INCENTIVE CRITERIA
                  </button>
                </h2>
                <div
                  id="collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    2 Directs Must{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1500"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="false"
                    aria-controls="collapsesix"
                  >
                    ROYALTY REWARDS
                  </button>
                </h2>
                <div
                  id="collapsesix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    Royalty Rewards Count Will Be Calculated on Next to Next{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1800"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseseven"
                    aria-expanded="false"
                    aria-controls="collapseseven"
                  >
                    PACKAGE RE TOP UP
                  </button>
                </h2>
                <div
                  id="collapseseven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    Upgrade Same Value Package Or Above Packages{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="2100"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseeight"
                    aria-expanded="false"
                    aria-controls="collapseeight"
                  >
                    ROYALTY REWARDS CRITERIA
                  </button>
                </h2>
                <div
                  id="collapseeight"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p class="para">
                    ID As To Be Active To ReceiveRoyalty Rewards{" "}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
